.appointment-container .top-appointment-container {
    text-align: center;
    margin-bottom: 20px;
    background-color: rgb(2, 1, 16);
    border-radius: 8px;
    box-shadow: 0 0 30px rgba(15, 6, 30, 0.797);
  }
  
  .appointment-container .top-appointment-container h1 {
    color: #a7abae;
    font-size: 2em;
    font-family: monospace;
    font-variant: small-caps;
  }
  
  .top-appointment-container {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }

  .appointment-container h3 {
    font-size: 1em;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    color: rgb(62, 19, 19);
    }
  
  .appointment-container p {
    font-size: 2.5em;
    text-align: center;
    font-family: monospace;
    font-variant: small-caps;
    color: #a7abae;
  }