/* Header.css */

.header-container {
  background-color:#34518e;
  border-radius: 25px;
  text-align: center;
  width: 80%;
  padding: 20px 0;
  margin: auto;
  box-shadow: 0 4px 6px rgba(110, 132, 219, 0.872);
}

.header-container h1 {
  color: #ffffff;
  text-align: center;
  font-family: fantasy;
  font-size: 1.5rem;
  margin: 0;
  padding: 0;
}

.header-container h2 {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 1em;
}

.header-container img {
  width: 50%;
  margin: 0 auto;
  display: block;
}

.header-container .music-links {
  background-color: #000000;
  padding: 1%;
  margin-left: 40%;
  margin-right: 40%;
  margin-bottom: -1%;
  display: flex;
  border-radius: 15%;
}