 .top-mission-container h1 {
    color: #ffffff;
    font-size: 2em;
    font-family: monospace;
    font-variant: small-caps;
  }
  
  .top-mission-container {
    padding: 20px;
    text-align: center;
    background-color: #000000;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    
  }
  
  .top-mission-container p {
    text-align: center;
    font-size: 2.5em;
    text-align: center;
    font-family: monospace;
    font-variant: small-caps;
    color: #a7abae;
  }

.top-mission-container section {
  padding: 20px;
  background-color: #a7abae;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.main-content {
  background-color: rgb(152, 146, 133);
  margin-left: 25%;
  margin-right: 25%;
  border-radius: 7%;
}

.main-content h2 {
  padding: 3%;
  text-align: center;
  color: #000000;
  text-decoration: underline;
}

.main-content p {
  padding-top: 2%;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  text-align: center;
  font-size: 1.25em;
  padding: 3%;
  margin-top: 3px;
}