.contact-container{
    width: 100%;
}

.contact-container .main-content{
    padding: 20px;
    background-color: rgb(52, 81, 142);  
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    width: 80%;
    margin: auto;
}

.top-contact-container {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
}

.contact-container .top-contact-container {
    text-align: center;
    margin-bottom: 20px;
    background-color: rgb(2, 1, 16);
    border-radius: 8px;
    box-shadow: 0 0 30px rgba(15, 6, 30, 0.797);
}

.contact-container .top-contact-container h1 {
    color: #a7abae;
    font-size: 2em;
    font-family: monospace;
    font-variant: small-caps;
}

.contact-container section {
    padding: 20px;
    background-color: #a7abae;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
}

.contact-container section span {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    color: hsl(0, 27%, 29%);
    line-height: 1.5;
}

.contact-container section span {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    color: hsl(0, 0%, 0%);
    line-height: 1.5;
}

.contact-container section label {
    font-size: 1.25em;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: bold;
    color: #000204;
    margin-left: 15px;
}

.contact-container section .name-span {
    font-size: 1em;
    font-family:Arial, Helvetica, sans-serif;
    color: #000000;
}

.contact-container section h2 {
    color: #000000;
    font-size: 1.5em;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    padding: 15px;
    text-decoration: underline;
}

.contact-container section p {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    line-height: 0.5;
    padding-top: 10px;
    padding-bottom: 10px;
}