.home-container {
  margin: 0 auto;
  background-color: rgb(83, 81, 81);;
}

.home-container{
  width: 100%;
}

.home-container .main-content{
  padding: 20px;
  background-color: #34518e;
  border-radius: 10px;
  box-shadow: 0 0 10px #7087bb;
  margin-top: 20px;
  width: 80%;
  margin: auto;
}

.top-home-container {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(130, 100, 100, 0.1);
  margin-top: 20px;
}

.home-container .top-home-container {
  text-align: center;
  margin-bottom: 20px;
  background-color: rgb(2, 1, 16);
  border-radius: 8px;
  box-shadow: 0 0 30px rgba(15, 6, 30, 0.797);
}

.home-container .top-home-container h1 {
  color: #a7abae;
  font-size: 2em;
  font-family: monospace;
  font-variant: small-caps;
}

.home-container .top-home-container p {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color: hsl(0, 0%, 0%);
  line-height: 1.25;
  margin-top: 10px;
  padding: 10px;
}

.home-container section {
  padding: 20px;
  background-color: #a7abae;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.home-container section h2 {
  color: #000000;
  font-size: 1.5em;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;  padding: 5px;
}

.home-container section p {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  line-height: 2;
  margin-top: 10px;
  padding: 10px;
}

.home-container section p::first-letter {
  font-size: 1.25em;
  font-weight: bold;
}

.home-container footer {
  text-align: center;
  margin-top: 20px;
}